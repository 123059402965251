import slugify from '@sindresorhus/slugify'

function slugifyExercises(exercises, partURL) {
  return exercises.map((exo) => {
    const url =
      typeof exo.title !== 'string' ? null : `${partURL}/${slugify(exo.title)}`
    return { url, def: exo.title ? { ...exo, url } : exo }
  })
}

function slugifyProgramme(programme, { prefix }) {
  return programme.map(({ name, exercises }) => {
    const url = prefix + slugify(name)

    return { name, exercises: slugifyExercises(exercises, url), url }
  })
}

export default function slugifySyllabus({ name, programme, url }, { prefix }) {
  prefix = prefix ? `/${prefix}/` : '/'
  return {
    name,
    prefix,
    programme: slugifyProgramme(programme, { prefix }),
    url,
  }
}
