import { createStore } from 'redux'
import { persistReducer, persistStore } from 'redux-persist'
import localForage from 'localforage'

import reducer from './reducers'

localForage.config({ name: 'ESLab v2', storeName: 'eslab' })

const esLabReducer = persistReducer(
  { key: '@eslab2', storage: localForage },
  reducer
)

const enhancer = window.__REDUX_DEVTOOLS_EXTENSION__
  ? window.__REDUX_DEVTOOLS_EXTENSION__()
  : (x) => x

const store = createStore(esLabReducer, undefined, enhancer)
const persistor = persistStore(store)

if (module.hot) {
  module.hot.accept('./reducers', () => {
    store.replaceReducer(require('./reducers').default)
  })
}

export { store, persistor }

export default store
