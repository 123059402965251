import 'tachyons/css/tachyons.css'

import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom'

import Loadable from 'react-loadable'
import { Provider } from 'react-redux'
import React from 'react'
import SplashScreen from './front/components/SplashScreen'
import slugifySyllabus from './slugifier'
import store from './front/store'

/* eslint react/prop-types: 0, react/display-name: 0 */

function dynamicallyLoadSyllabus({
  match: {
    params: { syllabus: token },
  },
}) {
  const SyllabusLoader = Loadable.Map({
    delay: 300,
    loader: {
      data: () =>
        import(/* webpackChunkName: "[request]" */ `./syllabus/${token}`),
      ui: () =>
        import(/* webpackChunkName: "main-ui" */ './front/components/Syllabus'),
    },
    loading: SplashScreen,
    render: ({ data: { default: syllabus }, ui: { default: Syllabus } }) => {
      return (
        <Syllabus syllabus={slugifySyllabus(syllabus, { prefix: token })} />
      )
    },
  })
  return <SyllabusLoader />
}

const App = () => (
  <Provider store={store}>
    <Router>
      <Switch>
        <Route path='/:syllabus' render={dynamicallyLoadSyllabus} />
        <Redirect to='/es-total' />
      </Switch>
    </Router>
  </Provider>
)

export default App
