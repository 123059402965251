import { combineReducers } from 'redux'

import settings from './settings'
import storedCodes from './storedCodes'

const reducer = combineReducers({
  settings,
  storedCodes,
})

export default reducer
