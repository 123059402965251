const TOGGLE_THEME = 'eslab/settings/TOGGLE_THEME'

export default function reduceSettings(state = { theme: 'dark' }, action) {
  switch (action.type) {
    case TOGGLE_THEME: {
      const theme = state.theme === 'light' ? 'dark' : 'light'
      return { ...state, theme }
    }
    default:
      return state
  }
}

export function toggleTheme() {
  return { type: TOGGLE_THEME }
}
