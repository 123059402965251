import { and, between, integer } from 'airbnb-prop-types'
import {
  arrayOf,
  bool,
  func,
  node,
  object,
  oneOf,
  oneOfType,
  shape,
  string,
} from 'prop-types'

export { arrayOf, bool, func, node, object, oneOf, string }

export const integerRange = ({ min, max }) =>
  and([integer(), between({ gte: min, lte: max })])

export const ExerciseExamplesPropType = arrayOf(string)

export const ExerciseResourcesPropType = arrayOf(
  shape({
    title: string.isRequired,
    url: string.isRequired,
  })
)

export const ExerciseResultsPropType = arrayOf(
  shape({
    message: string.isRequired,
    success: bool.isRequired,
    title: string.isRequired,
  })
)

export const ExerciseTestsPropType = arrayOf(
  shape({
    // `code` is used for symptomatic tests
    code: string,
    // `run` is used for AST tests
    run: func,
    title: string.isRequired,
  })
)

export const ExercisePropType = shape({
  defaultCode: string.isRequired,
  difficulty: integerRange({ min: 1, max: 5 }).isRequired,
  examples: ExerciseExamplesPropType,
  resources: ExerciseResourcesPropType,
  solution: string.isRequired,
  tests: ExerciseTestsPropType,
  text: string.isRequired,
  title: string.isRequired,
  url: string,
})

export const PartPropType = shape({
  name: string.isRequired,
  exercises: arrayOf(
    shape({
      def: oneOfType([ExercisePropType, string]).isRequired,
      url: string,
    })
  ),
  url: string.isRequired,
})

export const SyllabusPropType = shape({
  name: string.isRequired,
  programme: arrayOf(PartPropType).isRequired,
  url: string,
})
