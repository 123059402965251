import React from 'react'

import { bool, func, object } from '../../prop-types'

const SplashScreen = ({ error, retry, pastDelay }) => {
  if (!error && !pastDelay) {
    return null
  }
  console.log(error)

  const content = error ? (
    <React.Fragment>
      <p>
        Message : <tt className='dark-red'>{error.message}</tt>
      </p>
      <p className='retry'>
        <a className='link f4 dim br2 ph3 pv2 dib white bg-red' href='/'>
          Je m’en fous je ré-essaie !
        </a>
      </p>
    </React.Fragment>
  ) : (
    <p>Ça met un moment, désolés…</p>
  )

  const blam = (
    <span role='img' aria-label=''>
      🔥
    </span>
  )
  const hourGlass = (
    <span role='img' aria-label=''>
      ⏳
    </span>
  )

  return (
    <div className={`sans-serif tc pa2 f3 ${error ? 'red' : 'orange'}`}>
      {error ? (
        <h1>
          {blam} Une erreur a empêché le chargement ! {blam}
        </h1>
      ) : (
        <h1>
          {hourGlass} ESLab se charge… {hourGlass}
        </h1>
      )}
      {content}
    </div>
  )
}

SplashScreen.propTypes = {
  error: object,
  retry: func,
  pastDelay: bool,
}

export default SplashScreen
