const PURGE_ALL_CODE = 'eslab/storedCodes/PURGE_ALL_CODE'
const PURGE_EXERCISE = 'eslab/storedCodes/PURGE_EXERCISE'
const PURGE_PART = 'eslab/storedCodes/PURGE_PART'
const STORE_CODE = 'eslab/storedCodes/STORE'

export default function reduceStoredCodes(state = {}, action) {
  switch (action.type) {
    case PURGE_ALL_CODE:
      return {}
    case PURGE_EXERCISE:
      state = { ...state }
      delete state[action.payload.url]
      return state
    case PURGE_PART: {
      const partURL = action.payload.url
      return Object.entries(state).reduce((acc, [url, exoCode]) => {
        if (!url.startsWith(partURL)) {
          acc[url] = exoCode
        }
        return acc
      }, {})
    }
    case STORE_CODE: {
      const { url, code } = action.payload
      return { ...state, [url]: code }
    }
    default:
      return state
  }
}

export function purgeAllCode() {
  return { type: PURGE_ALL_CODE }
}

export function purgeExercise(url) {
  return { type: PURGE_EXERCISE, payload: { url } }
}

export function purgePart(url) {
  return { type: PURGE_PART, payload: { url } }
}

export function storeCode(url, code) {
  return { type: STORE_CODE, payload: { url, code } }
}
